<template>
  <v-card id="account-setting-card">
    <v-tabs v-model="tab" show-arrows>
      <v-tab v-for="tab in tabs" :key="tab.id">
        <v-icon size="20" class="me-3">
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <client-settings-office></client-settings-office>
      </v-tab-item>
      <v-tab-item>
        <client-settings-collaborators></client-settings-collaborators>
      </v-tab-item>
      <v-tab-item>
        <client-settings-install></client-settings-install>
      </v-tab-item>
      <v-tab-item> </v-tab-item>
      <v-tab-item> </v-tab-item>
      <v-tab-item> </v-tab-item>

      <v-tab-item>
        <client-settings-office-franchise></client-settings-office-franchise>
      </v-tab-item>
      <v-tab-item>
        <client-settings-office-install></client-settings-office-install>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import "@mdi/font/css/materialdesignicons.css" // Load icons
import { ref } from "@vue/composition-api"

import ClientSettingsOffice from "./ClientSettingsOffice.vue"
import ClientSettingsCollaborators from "./ClientSettingsCollaborators.vue"
import ClientSettingsInstall from "./ClientSettingsInstall.vue"
import ClientSettingsOfficeFranchise from "./ClientSettingsOfficeFranchise.vue"
import ClientSettingsOfficeInstall from "./ClientSettingsOfficeInstall.vue"

export default {
  components: {
    ClientSettingsOffice,
    ClientSettingsCollaborators,
    ClientSettingsInstall,
    ClientSettingsOfficeFranchise,
    ClientSettingsOfficeInstall,
  },
  setup() {
    const tab = ref("")

    // tabs
    const tabs = [
      { id: 1, title: "Mon espace client", icon: "mdi-home" },
      { id: 2, title: "Mes franchisés", icon: "mdi-account-group" },
      { id: 3, title: "Les installateurs", icon: "mdi-office-building-cog" },
      { id: 4, title: "Mes collaborateurs", icon: "mdi-folder" },
      { id: 5, title: "Mes contrats", icon: "mdi-file" },
      { id: 6, title: "Mes outils", icon: "mdi-cog" },
      { id: 7, title: "Mon espace franchise", icon: "mdi-home" },
      { id: 8, title: "Mon espace installateur", icon: "mdi-home" },
    ]

    return { tab, tabs }
  },
}
</script>
