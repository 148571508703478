<template>
  <v-container fluid>
    <loading transition="fade" :can-cancel="false" :is-full-page="false" loader="dots" color="#efb639" :opacity="0.9">
    </loading>

    <v-dialog persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du compte</span>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <h3>Êtes-vous sûr de vouloir supprimer le compte : {{ editedItem.completeName }} ?</h3>
            <p>
              En supprimant ce compte, vous consentez à la suppression des données à caractère personnelle lié à ce
              compte. Vous consentez également à ne plus pouvoir consulter :
            </p>
            <ul>
              <li>Les statistiques</li>
              <li>Les données enregistrées (Nom, prénom, ip...)</li>
              <li>Les données bancaires</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="EoleYellow" text>Annuler</v-btn>
          <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm(editedItem.id)"
            >Confirmer la suppression</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card outlined class="pa-5" elevation="4">
      <v-row>
        <v-col cols="12" md="7">
          <v-card-text class="pa-5">
            <div class="justify-left row">
              <div v-for="keyInstall in installateurs" :key="keyInstall.id" class="col-md-6">
                <v-card v-on:click="chooseTypology(`${keyInstall.desc}`)" style="background-color: #e1e5e9">
                  <v-card-title>
                    {{ keyInstall.name }}
                  </v-card-title>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-col>

        <v-col class="d-flex justify-center" cols="12" md="2">
          <v-card height="120" width="144" elevation="6">
            <v-card-text class="pa-5">
              <h3 class="text-center">98</h3>
              <p class="text-h7 text-center" style="color: #fcc03c">5 213 000,56 €</p>
              <p class="text-h7 text--primary text-center">Actifs</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="text-center pa-10" cols="12" md="2">
          <v-dialog>
            <template class="text-bottom" v-slot:activator="{ on, attrs }">
              <v-btn color="EoleBlue" class="mb-2 white--text" v-bind="attrs" v-on="on" big>
                <v-icon left> mdi-account-multiple-plus </v-icon>
                Ajouter un installateur
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="text-h5">TEST </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="formSiretInformations" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          :rules="[siretRules.required, siretRules.min]"
                          class="inputSive mx-auto mt-2 mb-2"
                          type="number"
                          counter="14"
                          outlined
                          label="Entrez le n° de siret du franchisé"
                          prepend-inner-icon="mdi-briefcase"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="warning" text>Annuler</v-btn>
                <v-btn class="white--text" color="EoleBlue">Ajouter </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="my-5 mx-5">
      <v-col v-if="this.radios === 'Inactifs'" cols="12" md="3">
        <v-treeview
          rounded
          open-all
          activatable
          open-on-click
          v-model="selection"
          @update:active="onUpdate"
          transition
          :items="itemsInactifs"
        >
        </v-treeview>
      </v-col>
      <v-col v-else cols="12" md="3">
        <v-treeview
          rounded
          open-all
          activatable
          open-on-click
          v-model="selection"
          @update:active="onUpdate"
          transition
          :items="itemsActifs"
        >
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="12" md="9" class="d-flex">
        <v-scroll-y-transition mode="out-in">
          <v-row v-if="this.idSelect == -1" align="center" class="text-left">
            <v-col>
              <v-alert type="info" color="EoleBlue" prominent icon="mdi-account" text dense>
                Veuillez selectionner un installateur à afficher dans cette fenêtre.</v-alert
              >
            </v-col>
          </v-row>
          <v-card v-if="this.idSelect != -1" class="pt-6 mx-auto" flat>
            <v-card outlined class="text-center pa-5" elevation="4">
              <v-card-title class="EoleBlueLighten mb-5 justify-center"> {{ affName }} </v-card-title>
              <v-card-text class="text-h6" style="color: #fcc03c"
                ><strong> Franchise principal : {{ affFranchiseur }}</strong></v-card-text
              >
              <v-card-text><strong>Entrée comme installateur :</strong> {{ affDateE }}</v-card-text>
              <v-card-text v-if="this.radios == 'Inactifs'"><strong>Sortie :</strong> {{ affDateS }}</v-card-text>
              <v-card-text><strong>Dirigeant :</strong> {{ affLeaders }}</v-card-text>

              <v-row class="pa-5 justify-center">
                <v-col class="d-flex justify-center" v-for="keyB in button" :key="keyB.id" cols="12" md="4">
                  <v-btn style="color: white" width="100%" block :color="keyB.color">
                    <v-icon left> {{ keyB.icon }} </v-icon>
                    {{ keyB.name }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-card-title class="EoleBlueLighten mb-5 justify-center"> Informations général </v-card-title>
              <v-row>
                <v-col class="d-flex justify-center" cols="12" md="6">
                  <v-card class="mx-auto" width="100%" elevation="6">
                    <v-card-text class="pa-5">
                      <h3 class="text-center">{{ affName }}</h3>
                      <p class="text-h7 text-center" style="color: #fcc03c">{{ affPostal }}</p>
                      <p class="text-h7 text-center" style="color: #1697f6">Adresse de correspondances</p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col class="d-flex justify-center" cols="12" md="6">
                  <v-card class="mx-auto" width="100%" elevation="6">
                    <v-card-text class="pa-5">
                      <h3 class="text-center">{{ affLeaders }}</h3>
                      <p class="text-h7 text-center" style="color: #fcc03c">{{ affMail }}</p>
                      <p class="text-h7 text-center">{{ affNum }}</p>
                      <p class="text-h7 text-center" style="color: #1697f6">Interlocuteur Pricipal</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" md="6">
                  <v-card class="mx-auto" width="100%" elevation="6">
                    <v-card-text class="pa-5">
                      <h3 class="text-center">N</h3>
                      <p class="text-h6 text-center" style="color: #fcc03c">{{ affTendCA }}</p>
                      <p class="text-h7 text--primary text-center">Tendance Chiffre d'affaire</p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col class="d-flex" cols="12" md="6">
                  <v-card class="mx-auto" width="100%" elevation="6">
                    <v-card-text class="pa-5">
                      <h3 class="text-center">N-1</h3>
                      <p class="text-h6 text-center" style="color: #fcc03c">{{ affCA }}</p>
                      <p class="text-h7 text--primary text-center">Chiffre d'affaire</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <br />
              <v-card-title class="EoleBlueLighten justify-center"> Mes Attestations </v-card-title>
              <v-row class="pa-5">
                <v-col class="d-flex" cols="12" md="6">
                  <v-card class="mx-auto" width="100%" elevation="6">
                    <v-card-text class="pa-5">
                      <h3 class="text-center">Attestation Responsabilité civile</h3>
                      <br />
                      <p class="text-h7 text-center" style="color: #fcc03c">{{ affAttestationRC }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col class="d-flex" cols="12" md="6">
                  <v-card class="mx-auto" width="100%" elevation="6">
                    <v-card-text class="pa-5">
                      <h3 class="text-center">Attestation décennale</h3>
                      <br />
                      <p class="text-h7 text-center" style="color: #fcc03c">{{ affAttestationDec }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-scroll-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import "vue-loading-overlay/dist/vue-loading.css"

Vue.use(Toast, { maxToasts: 2, newestOnTop: true })

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  watch: {
    selection(newValue) {
      this.onUpdate(newValue)
    },
  },
  data: () => ({
    selection: [],
    idSelect: -1,
    usersActifs: [
      {
        id: 1,
        name: "SARL INSTALLATEUR 1",
        dateE: "06/05/2019",
        leaders: "DEOUF",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SARL DUCHEMIN",
      },
      {
        id: 2,
        name: "SARL INSTALLATEUR 2",
        dateE: "06/08/2016",
        leaders: "SALLOMON",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SARL DUCH",
      },
      {
        id: 3,
        name: "SARL INSTALLATEUR 5",
        dateE: "09/02/2012",
        leaders: "HENRY",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SARL LDFF",
      },
      {
        id: 4,
        name: "SARL INSTALLATEUR 8",
        dateE: "25/09/2022",
        leaders: "GEORGE",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SARL FDS",
      },
    ],
    usersInactifs: [
      {
        id: 1,
        name: "EURL INSTALLATEUR 11",
        dateE: "12/06/2000",
        dateS: "13/07/2025",
        leaders: "SEB",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "EURL FDF",
      },
      {
        id: 2,
        name: "SAS INSTALLATEUR 13",
        dateE: "03/09/2052",
        dateS: "13/07/2025",
        leaders: "JEAN",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SAS LLL",
      },
      {
        id: 3,
        name: "EI INSTALLATEUR 10",
        dateE: "01/01/2049",
        dateS: "13/07/2025",
        leaders: "DAVID",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "EI DFG",
      },
      {
        id: 4,
        name: "SARL INSTALLATEUR 15",
        dateE: "01/01/2079",
        dateS: "13/07/2025",
        leaders: "MATHIS",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SARL MADD",
      },
      {
        id: 5,
        name: "SARL INSTALLATEUR 16",
        dateE: "01/01/2099",
        dateS: "13/07/2025",
        leaders: "ROMAN",
        mail: "tartampion@dulac.fr",
        num: "06.58.69.47.65",
        postal: "Mon adresse Postale",
        tendCA: "363 000€",
        ca: "213 000,56€",
        attesRC: "XX",
        attesDec: "XX",
        franchiseurP: "SARL POPPO",
      },
    ],

    installateurs: [
      { id: 1, name: "Les installateurs actifs", desc: "Actifs" },
      { id: 2, name: "Les installateurs inactifs", desc: "Inactifs" },
    ],
    radios: null,
    typeUser: null,

    button: [
      { id: 1, name: "MODIFIER", color: "primary", icon: "mdi-pencil" },
      { id: 2, name: "DESACTIVER", color: "rgb(230,0,0)", icon: "mdi-alert-octagon" },
    ],

    editedIndex: -1,
    editedItem: {
      id: null,
      firstname: "",
      lastname: "",
      lastConnection: "",
      lastIPConnected: "",
      statut: "collaborateur",
      email: "",
      phoneNumber: "",
    },

    siretRules: {
      required: v => !!v || "N° de siret requis",
      min: v => v.length === 14 || "Le siret doit être composé de 14 chiffres",
    },
  }),

  computed: {
    itemsActifs() {
      return [
        {
          name: `Mes installateurs ${this.radios}(${this.usersActifs.length})`,
          children: this.usersActifs,
        },
      ]
    },
    itemsInactifs() {
      return [
        {
          name: `Mes installateurs ${this.radios}(${this.usersInactifs.length})`,
          children: this.usersInactifs,
        },
      ]
    },
    affName: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].name
      } else {
        return this.usersInactifs[this.idSelect].name
      }
    },
    affDateE: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].dateE
      } else {
        return this.usersInactifs[this.idSelect].dateE
      }
    },
    affDateS: function () {
      if (this.radios == "Actifs") {
        pass
      } else {
        return this.usersInactifs[this.idSelect].dateS
      }
    },
    affLeaders: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].leaders
      } else {
        return this.usersInactifs[this.idSelect].leaders
      }
    },
    affMail: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].mail
      } else {
        return this.usersInactifs[this.idSelect].mail
      }
    },
    affNum: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].num
      } else {
        return this.usersInactifs[this.idSelect].num
      }
    },
    affPostal: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].postal
      } else {
        return this.usersInactifs[this.idSelect].postal
      }
    },
    affTendCA: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].tendCA
      } else {
        return this.usersInactifs[this.idSelect].tendCA
      }
    },
    affCA: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].ca
      } else {
        return this.usersInactifs[this.idSelect].ca
      }
    },
    affAttestationRC: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].attesRC
      } else {
        return this.usersInactifs[this.idSelect].attesRC
      }
    },
    affAttestationDec: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].attesDec
      } else {
        return this.usersInactifs[this.idSelect].attesDec
      }
    },
    affFranchiseur: function () {
      if (this.radios == "Actifs") {
        return this.usersActifs[this.idSelect].franchiseurP
      } else {
        return this.usersInactifs[this.idSelect].franchiseurP
      }
    },
  },

  methods: {
    chooseTypology(typology) {
      this.radios = typology
      if (this.radios == "Inactifs") {
        this.typeUser = "usersInactifs"
      } else {
        this.typeUser = "usersInactifs"
      }
    },
    onUpdate(selection) {
      this.idSelect = selection[0]
      this.idSelect = this.idSelect - 1
    },
  },
}
</script>
<style lang="scss">
.v-treeview:hover {
  cursor: pointer;
}

.v-progress-circular {
  font-size: 12px;
}
</style>
